<template>
  <div class="node-record">
    <header-bar :showNotice="false" :showBack="true" :transparent="true" @setLanguage="setLanguageAfter"></header-bar>

    <div style="margin-top: -90px;">
      <title-name :title="$t('Pledge.zhiyajilu')" label="Pledge records"></title-name>
    </div>

    <div class="list">
      <div class="list-name">
        <div>
          {{ $t('Pledge.shijian') }}
        </div>
        <div class="justify-center">
          {{ $t('Pledge.zhouqi') }}
        </div>
        <div class="justify-center">
          {{ $t('Pledge.jine') }}
        </div>
        <div class="justify-end">
          {{ $t('Pledge.zhuangtai') }}
        </div>
      </div>
      <van-list
        v-if="list.length > 0"
        v-model:loading="loading"
        :finished="finished"
        :finished-text="$t('NodeRecord.finished')"
        :immediate-check="false"
        offset="10"
        @load="onLoad"
      >
        <div class="list-val" v-for="item in list" :key="item.id">
          <div>{{ item.cdate }}</div>
          <div class="justify-center text-center">{{ item.pzhouqi }}</div>
          <div class="justify-center text-center">{{ item.jine }}</div>
          <div class="justify-end">
            <div>
              <div class="btn" :class="{'disable-btn': item.countdown < 0}" v-if="item.showCz" @click="revoke(item.id, item.countdown)">
                {{ $t('Pledge.chezi') }}
              </div>
              <div v-else-if="item.state == 0" style="color: green;">{{ $t('Pledge.shouyizhong') }}</div>
              <div v-else-if="item.state == 1" style="color: #e02020;">{{ $t('Pledge.yichezi') }}</div>
              <div v-if="item.countdown > 0">
                <van-count-down :time="item.countdown" v-if="item.showCz" />
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <empty text="No records" v-if="list.length == 0" />
    </div>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar'
import TitleName from '@/components/TitleName'
import Empty from '@/components/Empty'
import { ref } from 'vue'
import Web3Helper from "@/assets/js/web3helper.js";

import { List, CountDown } from "vant";

export default {
  name: 'Bill',
  components: {
    HeaderBar,
    TitleName,
    Empty,
    [List.name]: List,
    [CountDown.name]: CountDown,
  },
  setup() {
    return {
      us: ref(null),
      pageIndex: ref(1),
      list: ref([]),
      loading: ref(false),
      finished: ref(false),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getdata()
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    setLanguageAfter() {
      this.pageIndex = 1
      this.loading = false
      this.finished = false
      this.list = []
      this.getdata()
    },
    // 顶部组件的后退方法
    onBack() {
      this.$emit("close")
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/ImpawnUser/List",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          uid: _this.us.id,
          pageSize: 10,
          pageIndex: _this.pageIndex
        },
        (res) => {
          _this.loading = false
          if(res.data.code == 0) {
            _this.$q.dialog({
							message: res.data.msg,
						});
          }
          let data = res.data.data
          data.forEach(item => {
            let time = new Date().getTime()
            let etime = new Date(item.edate).getTime()
            if(item.state == 0 && time >= etime) {
              item.showCz = true
              let cztime = etime + 86400000
              item.countdown =  cztime - time
            }
          })
          // console.log(data)
          if(data.length == 0) {
            _this.finished = true
          } else {
            _this.finished = false
          }
          _this.list.push(...data)
        }
      )
    },
    revoke(pid, countdown) {
      let _this = this
      if(countdown < 0) return
      this.$q.dialog({
        title: this.$t('Base.tishi'),
        message: this.$t('Pledge.querenchezi'),
        ok: {
          label: this.$t('Base.queding'),
          flat: true,
        },
        cancel: {
          label: this.$t('Base.quxiao'),
          flat: true,
        },
        persistent: true,
      }).onOk(() => {
        _this.$q.loading.show({
          message: _this.$t('Pledge.chezizhong')
        })
        let web3Helper = new Web3Helper();
        web3Helper.getSign().then((signStr) => {
          _this.$request.post(
            "api/ImpawnUser/ImpawnRevoke",
            {
              token: _this.$utils.getloc("token"),
              userid: _this.us.userid,
              uid: _this.us.id,
              signStr: signStr,
              pid
            },
            (res) => {
              _this.$q.loading.hide();
              // console.log(res)
              _this.$q.dialog({
                message: res.data.msg,
              });
              if(res.data.code == 100) {
                _this.loading = false
                _this.loading = false
                _this.pageIndex = 1
                _this.list = []
                _this.getdata()
              }
            }
          )
        })
      })
    }
  }
}
</script>

<style scoped>
.node-record {
  min-height: 100vh;
  background: url('~@/assets/img/base/banner-background.jpg') no-repeat;
  background-size: 100% 100%;
}

.back-wrap {
  padding: 0 12px;
}

.list {
  margin: 24px 12px 0;
  padding: 0 10px;
}

.list-name {
  display: flex;
  color: gray;
}

.list-name > div {
  flex: 1;
  display: flex;
  align-items: center;
}

.list-name img {
  margin-left: 8px;
  width: 14px;
}

.list-val {
  padding: 8px 0;
  display: flex;
  align-items: center;
}

.list-val > div {
  flex: 1;
  display: flex;
  align-items: center;
}

.btn {
  padding: 3px 12px;
  color: #fff;
  border-radius: 4px;
  background-color: #e02020;
}

.disable-btn {
  opacity: 0.7;
}
</style>